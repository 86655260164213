export const duration = 1;
export const textIndustry = [
  "Agriculture",
  "Food",
  "Health",
  "Clothing",
  "Investor",
];
export const textAgricultureType = [
  "Livestock",
  "Grain",
  "Mixed",
  "Vegetable",
  "Fruit",
];
export const textAgricultureSubtype = [
  "Farmer",
  "Expert",
  "Products",
  "Investor",
  "Research",
];

export const textHealthType = [
  "Longevity",
  "Diabetes",
  "Menopause",
  "Community",
];
export const textHealthSubtype = ["User", "Expert", "Supplier", "Investor"];

export const animationAgricultureTitle = "Transition to regenerative farming";
export const animationAgricultureText = [
  "Get paid more & paid up front",
  "Simple & safe steps",
  "Reliable science & compliance",
];

export const animationHealthTitle =
  "Start your health journey with Fresh Earth";
export const animationHealthText = [
  "Experience Proven Results",
  "Clear & Simple Path",
  "Trusted Science & Transparency",
];

export const animationInvestorTitle = "Invest in ESG";
export const animationInvestorText = [
  "A new, trusted compliance layer",
  "Proven Science & Open Information",
  "Safety and assurance",
];

export enum Steps {
  Landing,
  Industry,
  IndustryType,
  IndustrySubType,
  Animation,
  Questionnaire,
}

type Mirroring = {
  [key: string]: string;
};

export const infoCardDescriptions: Mirroring = {
  Agriculture:
    "Global agricultural land use is 5 billion hectares, causing biodiversity loss, water waste, soil contamination, and up to 37% of global emissions. Regenerative practices like polycultures, drip irrigation, and no-till farming enhance biodiversity, water efficiency, and carbon sequestration.",
  Food: "The food industry faces high costs from chemical use, food waste, and processed foods. These include billions from foodborne illnesses and $1 trillion from waste. Regenerative practices like natural processing, food recovery, and humane farming can save billions by reducing healthcare costs and waste.",
  Health:
    "Chronic diseases like diabetes and heart disease, along with mental health issues, impose massive financial burdens, with mental health alone costing $2.5 trillion annually. Regenerative practices focusing on diets, physical activity, and mindfulness can reduce chronic diseases and improve mental health cost-effectively.",
  Clothing:
    "Cotton and natural fibers use 115 million hectares, causing biodiversity loss, water waste (2.6% of global use), and heavy pesticide use. Emissions from synthetic fibers are significant. Regenerative practices like organic cotton farming and efficient irrigation enhance biodiversity, water efficiency, and carbon sequestration.",
  Investor:
    "Global agricultural land use is 5 billion hectares, causing biodiversity loss, water waste, soil contamination, and up to 37% of global emissions. Regenerative practices like polycultures, drip irrigation, and no-till farming enhance biodiversity, water efficiency, and carbon sequestration.",
  Livestock:
    "Livestock production uses 3.3 billion hectares, causing 78% of terrestrial biodiversity loss, 8% of water use, high chemical use, and 14.5% of global emissions. Regenerative practices like rotational grazing and agroforestry enhance biodiversity, water efficiency, nutritional density, and carbon sequestration.",
  Grain:
    "Grain production uses 1.3 billion hectares, causing habitat destruction, high water use, intensive chemical use, and high emissions. Regenerative practices like polycultures, crop rotation, rainwater harvesting, and no-till farming promote biodiversity, water efficiency, nutritional density, and carbon sequestration.",
  Mixed:
    "Mixed farming systems use 2 billion hectares, causing biodiversity loss from habitat fragmentation, high water use, chemical dependence, and emissions. Regenerative practices like integrated crop-livestock systems and agroecology enhance biodiversity, water efficiency, nutritional density, and carbon sequestration.",
  Vegetable:
    "Vegetable production covers 50 million hectares, causing biodiversity loss from monocultures, high water use, extensive chemical use, and emissions. Regenerative practices like crop diversification, intercropping, efficient irrigation, and reduced tillage enhance biodiversity, water efficiency, nutritional density, and carbon sequestration.",
  Fruit:
    "Fruit production covers 70 million hectares, causing biodiversity loss from monocultures, high water use, heavy chemical use, and emissions. Regenerative practices like agroforestry, polycultures, efficient irrigation, and soil health improvement enhance biodiversity, water efficiency, nutritional density, and carbon sequestration.",
  Farmer:
    "Farmers create comprehensive digital twins of their farms on the Fresh Earth Exchange, enabling precise development, funding, and implementation of custom regenerative programs. This integration enhances both the ecological and economic performance of their land, driving the transition to regenerative agricultural practices.",
  Expert:
    "Experts on the Fresh Earth platform use their knowledge to craft verifiable regenerative programs and verification methodologies. They play a crucial role in designing scalable and fundable programs that not only generate environmental assets that offer financial returns, but they facilitate the commercialisation and widespread adoption of their pioneering programs.",
  Products:
    "Suppliers encompass producers of raw materials, processors who refine these into regenerative products, and the broader category of existing product providers. They support the creation, delivery and verification of regenerative agricultural programs by ensuring a continuous supply of quality, affordable and complaint inputs necessary for the successful execution of customised regenerative programs.",
  Investors:
    "Impact investors provide the essential capital for programs that demonstrate a potential for significant environmental and economic returns. Their investments are crucial for the pre-funding of these programs, enabling the large-scale implementation of sustainable practices.",
  Research:
    "Research at Fresh Earth connects global experts to accelerate learning and evolve regenerative practices. Through collaborative data sharing, deep leading and advanced technology our platform becomes a powerful engine, optimising the effectiveness of programs worldwide. This fosters rapid advancements, reduces costs, and maximizes impact, making regenerative programs more viable and scalable globally.",
};

export const infoCardTitles: Mirroring = {
  Agriculture: "The Agriculture Industry",
  Food: "The Food Industry",
  Health: "The Health Industry",
  Clothing: "The Clothing Industry",
  Investor: "The Agriculture Industry",
  Livestock: "The Livestock Industry",
  Grain: "The Grain Industry",
  Mixed: "The Mixed Industry",
  Vegetable: "The Vegetable Industry",
  Fruit: "The Fruit Industry",
  Farmer: "Farmers",
  Expert: "Experts",
  Products: "Suppliers (Producers, Processors, and Products)",
  Investors: "Impact Investors",
  Research: "Research",
};

export const xs = ["40vh", "20vh", "0vh", "-20vh", "-40vh"];

export const buttonVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    x: "0",
    scale: [0, 1],
  },
};
